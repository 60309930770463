import { makeService, modifyTimeSlot } from './api';

const relatedObjects = {
  topics: {
    byId: {},
    totalItems: 0
  },
}

const service = makeService('seminar_modules', relatedObjects);

service.beforeUpdate = function (payload) {

  return {
    ...(payload.timeSlot ? modifyTimeSlot(payload, true) : payload),
    ...(typeof payload.files !== 'undefined'
      ? {
        files: payload.files.map((attachment) => { return attachment.iri ? attachment.iri : attachment })
      }
      : {}),
  };
}

service.beforeCreate = function (payload) {
  // adjust uploads
  return {
    ...payload,
    ...(typeof payload.files !== 'undefined'
      ? {
        files: payload.files.map((attachment) => attachment.iri)
      }
      : {}),
  }
}

export default service;
