import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import {ENTRYPOINT} from '../config/entrypoint';
import SubmissionError from '../error/SubmissionError';
import {normalize} from './hydra';
import store from '../store';
import { formatDateTime } from '@/utils/dates';


const MIME_TYPE = 'application/ld+json';

const EXPIRED_TOKEN = 'Expired JWT Token';

const makeParamArray = (key, arr) =>
  arr.map(val => `${key}[]=${val}`).join('&');

export default function (id, options = {}) {
  if ('undefined' === typeof options.headers) {
    options.headers = new Headers();
  }

  if (null === options.headers.get('Accept')) {
    options.headers.set('Accept', MIME_TYPE);
  }

  if ('undefined' !== options.body && !(options.body instanceof FormData) && null === options.headers.get('Content-Type')) {
    options.headers.set('Content-Type', MIME_TYPE);
  }

  var authenticationToken = null;
  if (store.state.authentication.loggedIn) {
    authenticationToken = store.state.authentication.token;
  } else if (options.authenticationToken) {
    authenticationToken = options.authenticationToken;
  }
  if (authenticationToken !== null) {
    options.headers.set('Authorization', 'Bearer ' + authenticationToken);
  }

  if (options.params) {
    let queryString = Object.keys(options.params)
      .map(function (key) {
        // check for special filters
        if (key.startsWith('__Fdate')) {
          // DateFilter

          // deal with german format
          var [day, month, year] = options.params[key].date.split('.')
          var d = new Date(+year, month -1, +day)

          return `${options.params[key].property}[${options.params[key].when}]=${formatDateTime(d, 'YYYY-MM-DD')}`
        }
        if (key.startsWith('__Fisdue')) {
          if (options.params[key] === "1") {
            return 'date=isDue'
          }
        }
        if (key.startsWith('__FnotSentDunning')) {
          if (options.params[key] === "1") {
            return 'exists[dunnings.lastSentAt]=false'
          }
        }
        if (['__Fexists'].includes(key)) {
          switch (key) {
            case '__Fexists':
              return `exists[${Object.keys(options.params[key])[0]}]=${options.params[key][Object.keys(options.params[key])[0]]}`
          }
        } else {
          // also check for referenced filter
          if (isPlainObject(options.params[key])) {
            var subObj = options.params[key];
            if (isEmpty(subObj) === false) {
              return Object.keys(subObj).filter(subKey => (((subObj[subKey]) === true) || !isEmpty(subObj[subKey]))).map((subKey) => {
                if (Array.isArray(subObj[subKey])) {
                  return makeParamArray(subKey, subObj[subKey]);
                }
                if (isBoolean(subObj[subKey])) {
                  return `${key}[${subKey}]=${subObj[subKey]}`;
                }

                // support sub-sub entity filter like for seminar: offer -> customer -> customerNumber
                // what will go wrong? 🤣
                let searchKey = `${key}.${subKey}`;
                let searchValue = subObj[subKey]
                if (isPlainObject(searchValue)) {
                  const thirdKeys = Object.keys(searchValue);
                  if (thirdKeys.length === 1) {
                    searchKey = `${key}.${subKey}.${thirdKeys[0]}`;
                    searchValue = searchValue[thirdKeys[0]];
                  }
                }

                return `${searchKey}=${searchValue}`;
              }).join('&');
            }
          } else {
            // special handling of boolean filters w. checkboxes
            if (key.startsWith('__Fis')) {
              if (options.params[key] === true) {
                return `${key.split('_')[3]}=${key.split('_')[4]}`
              } else {
                return ''
              }
            }
            return Array.isArray(options.params[key]) ? makeParamArray(key, options.params[key]) : `${key}=${options.params[key]}`;
          }
        }
      })
      .join('&');
    id = `${id}?${queryString}`;
    if(options.params.signal){
      options.signal = options.params.signal;
      delete options.params.signal;
    }
  }

  const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

  if (!(options.body instanceof FormData)) {
    const payload = options.body && JSON.parse(options.body);
    if (isObject(payload) && payload['@id']) {
      options.body = JSON.stringify(normalize(payload, options.related));
    }
  }


  return global.fetch(new URL(id, entryPoint), options).then(response => {
    if (response.ok) {
      return response;
    }

    return response.json().then(
      json => {
        const error =
          json['hydra:description'] ||
          json['hydra:title'] ||
          'An error occurred.';

        if (response.status === 401 && json.code === 401) {
          if (json.message === EXPIRED_TOKEN) {
            store.commit('authentication/logout');
            location.reload(true);
          }
        }

        if (!json.violations) {
          throw Error(error);
        }

        let errors = {_error: error};
        json.violations.forEach(violation =>
          errors[violation.propertyPath]
            ? (errors[violation.propertyPath] +=
              '\n' + errors[violation.propertyPath])
            : (errors[violation.propertyPath] = violation.message)
        );

        throw new SubmissionError(errors);
      },
      () => {
        throw new Error(response.statusText || 'An error occurred.');
      }
    );
  });
}
