export default {
  '': '',
  'Account-Settings': 'Account',
  'Account': 'Account',
  'AccountConfirm': 'Bestätigung des Accounts',
  'AccountConfirmedText': 'Ihr Account wurde bestätigt',
  'AccountConfirmError': 'Bestätigungs-Token nicht gefunden. Evtl. wurde Ihr Accounts bereits bestätigt',
  'AccountConfirmText': 'Ihr Account wird bestätigt',
  'accounting': 'Belege',
  'Actions': 'Aktionen',
  'Active': 'Aktiv',
  'Add Participant': 'Teilnehmer hinzufügen',
  'Add': 'Hinzufügen',
  'AddAddress': 'Adresse Hinzufügen',
  'AddBankAccount': 'Bankverbindung hinzufügen',
  'AddContact': 'Kontakt Hinzufügen',
  'AddDiscount': 'Rabatt hinzufügen',
  'AddDunningLevel': 'Mahnstufe hinzufügen',
  'AddExpense': 'Ausgabe hinzufügen',
  'AddIssueUpdate': 'Antwort hinzufügen',
  'additional': 'Adresszusatz',
  'additionalConfirmationText': 'zusätzlicher Text für Teilnahmebestätigung',
  'additionalConfirmationTextShorten': 'zus. Text für Teilnahmebestätigung',
  'AddMenuEntry': 'Menu Eintrag hinzufügen',
  'AddModule': 'Modul hinzufügen',
  'AddNote': 'Notiz hinzufügen',
  'AddPage': 'Seite hinzufügen',
  'AddParticipant': 'Teilnehmer hinzufügen',
  'AddPayment': 'Zahlung hinzufügen',
  'AddPaymentMethod': 'Zahlungsmethode hinzufügen',
  'Address': 'Adresse',
  'Addresses': 'Adressen',
  'addressText': 'Anschrift',
  'addressType': 'Adress-Typ',
  'AddSeminar': 'Seminar hinzufügen',
  'AddSpeakerFee': 'Honorar hinzufügen',
  'AddTenantUser': 'Mandanten-Zugriff erstellen',
  'allCompleted': 'Abgeschlossen',
  'allConfirmed': 'Bestätigt',
  'AlreadyInvited': 'Teilnehmer/in wurde bereits eingeladen',
  'Amount': 'Summe',
  'Annual': 'Jährlich',
  'Appointment': 'Termin',
  'Are you sure you want persist and lock? Not reversible!': 'Das Dokument wirklich erstellen & sperren? Dies kann nicht Rückgängig gemacht werden!',
  'Are you sure you want to cancel this invoice?': 'Die Rechnung wirklich stornieren?',
  'Are you sure you want to cancel this already paid invoice?': 'Die bereits bezahlte Rechnung wirklich stornieren?',
  'invoiceEditTitle': 'Die Rechnung wirklich korrigieren?',
  'Are you sure you want to create a dunning for this invoice?': 'Sind Sie sicher, dass Sie eine Mahnung erstellen wollen?',
  'Are you sure you want to delete this item?': 'Wirklich löschen?',
  'invoiceEditMessage':'Bevor die Rechnung editiert werden kann, wird diese jetzt sofort storniert & die Stornorechnung per E-Mail versendet.',
  'yesEdit': 'Ja, jetzt stornieren & editieren',
  'InvoiceCorrected': 'Rechnung wurde storniert - Korrekturrechnung ist jetzt bereit',
  'assignedTo': 'Zugewiesen an',
  'AssignedTo': 'Zugewiesen an',
  'attachmentDeleted': 'Anhang wurde gelöscht',
  'Attachments': 'Datei-Anhänge',
  'attendanceListTemplate_DEFAULT': 'Standard',
  'attendanceListTemplate_INHOUSE_CASE_EMO_TRANS': 'EMO-Trans',
  'attendanceListTemplate': 'Template der Anwesenheitsliste',
  'author': 'Author',
  'Author': 'Author',
  'autoCreditNumber': 'Gutschriftsnummer wird automatisch vergeben.',
  'autoInvoiceNumber': 'Rechnungsnummer wird automatisch vergeben.',
  'autoInvoiceStatus': 'ReFr',
  'autoOfferNumber': 'Angebotsnummer wird automatisch vergeben.',
  'backend_groups': 'Backend Gruppen',
  'backend_users': 'Backend User',
  'Backend-Groups': 'Backend-Gruppen',
  'Backend-Users': 'Backend-User',
  'BankAccount': 'Bankkonto',
  'bankAccounts': 'Bankverbindungen',
  'Basics': 'Basics',
  'bic': 'BIC',
  'Billing': 'Rechnungen',
  'billingToCustomerForNumberOfParticipants': 'Rechnung an Kunden basierend auf Anzahl der Teilnehmer',
  'Budget': 'Überblick Finanzen',
  'businessAddress': 'Geschäftlich',
  'BusinessAddress': 'Geschäftsadresse',
  'BusinessAddresses': 'Geschäftsadressen',
  'Cancel': 'Abbrechen',
  'cancellation': 'Storno',
  'cancellationMailText': 'Storno Email Text',
  'cancelled': 'Storniert',
  'childPages': 'Unterseiten',
  'city': 'Stadt',
  'Close': 'Schließen',
  'CMS': 'CMS',
  'cmsContent': 'Beschreibung für Frontend',
  'comment': 'Kommentar',
  'Comment': 'Kommentar',
  'Company Settings': 'Unternehmen Einstellungen',
  'Company-Settings': 'Unternehmen',
  'company': 'Unternehmen',
  'Completed': 'Abgeschlossen',
  'ConfirmAndDownloadOfferConfirm': 'Auftrag bestätigen & Auftragsbestätigung herunterladen',
  'confirmAttachmentDeletion': 'Anhang wirklich löschen?',
  'confirmBlankUpdateMessage': 'Wollen Sie den Online Seminar-Raum erstellen?',
  'confirmDeactivateAutoInvoice': 'Automatischen Rechnungsversand deaktivieren.',
  'Confirmed': 'Bestätigt',
  'ConfirmOffer': 'Angebot bestätigen?',
  'ConfirmOfferConfirm': 'Auftrag bestätigen',
  'ConfirmOfferDecline': 'Auftrag abgelehnt',
  'ConfirmOfferMessage': 'Das Angebot bestätigen? Dies kann nicht Rückgängig gemacht werden!',
  'ConfirmOfferOk': 'Jetzt Angebot bestätigen',
  'confirmPrepareInvoiceForNumberOfParticipantsMessage': 'Rechnung an Kunden pro Teilnehmer vorbereiten?',
  'confirmReSendParticipantInvoiceMessage': 'Rechnung erneut an Teilnehmer versenden?',
  'confirmSendAllInvitationsMessage': 'An alle Teilnehmer den Einladungslink versenden?',
  'confirmSendParticipantConfirmationsMessage': 'Jetzt die Teilnahmebestätigung versenden?',
  'confirmSendParticipantInvoiceMessage': 'Rechnung an Teilnehmer senden?',
  'confirmSendParticipantsInvoiceMessage': 'Rechnungen an alle Teilnehmer schicken?',
  'confirmSendSpeakerCertificatesMessage': 'Jetzt die Referentenbescheinigung(en) versenden?',
  'confirmSendSpeakerInvitationsMessage': 'Allen Referenten jetzt Einladungslink versenden?',
  'confirmSetCancelledMessage': 'Seminar wirklich stornieren?',
  'confirmSetCompletedMessage': 'Seminar wirklich abschließen?',
  'confirmSetConfirmedMessage': 'Seminar bestätigen?',
  'confirmSetDisableMessage': 'Seminar deaktivieren?',
  'confirmSetEnableMessage': 'Seminar aktivieren?',
  'Contact': 'Kontaktart',
  'contactOnRegister': 'Kontakt Emails nach Kundenregistrierung',
  'contactPerson': 'Ansprechpartner/in',
  'contactPersonVenueInternal': 'Ansprechpartner/in am Veranstaltungsort (intern)',
  'Contacts': 'Kontaktarten',
  'ContactsPublic': 'Kontaktmöglichkeiten (öffentlich einsehbar)',
  'contactType': 'Art',
  'content': 'Inhalt',
  'CoSpeaker': 'Co-Referent',
  'country': 'Land',
  'Create an invoice for this offer?': 'Rechnung für dieses Angebot erstellen?',
  'Create': 'Erstellen',
  'CreateChild': 'Unterthema erstellen',
  'createChildPage': 'Unterseite erstellen',
  'Created': 'erstellt',
  'createdAt': 'Erstellt am',
  'CreateInvoice': 'Rechnung erstellen',
  'CreateMeeting': 'Online Seminar-Raum erstellen',
  'createMenuEntry': 'Menu Eintrag erstellen',
  'credit': 'Gutschrift',
  'creditMailText': 'Gutschrift Email Text',
  'creditNumber': 'Gutschrift-Nr.',
  'creditNumberRange': 'Gutschriftsnummer-Bereich',
  'credits': 'Gutschriften',
  'Credits': 'Gutschriften',
  'Customer': 'Kunde',
  'customerLoginEnabled': 'Kunden-Login möglich',
  'CustomerNoAddress': 'Kunde hat keine Adresse hinterlegt.',
  'customerNumber': 'Kunden-Nummer',
  'customerNumberRange': 'Kundennummer-Bereich',
  'customers': 'Kunden',
  'Customers': 'Kunden',
  'CustomerSettings': 'Kunden-Einstellungen',
  'Dashboard': 'Dashboard',
  'Data unavailable': 'Daten nicht verfügbar',
  'Date': 'Datum',
  'day': 'Tag',
  'Day': 'Tag',
  'DeclineOffer': 'Angebot ablehnen?',
  'DeclineOfferMessage': 'Das Angebot ablehnen? Dies kann nicht Rückgängig gemacht werden!',
  'DeclineOfferOk': 'Jetzt Angebot ablehnen',
  'default': 'Standard',
  'defaultAddress': 'Standard',
  'defaultAddresses': 'Standardadressen',
  'defaultCustomerGroup': 'Standard Kunden-Gruppe',
  'defaultDunningFooterText': 'Mahnung Fuß-Text',
  'defaultDunningHeaderText': 'Mahnung Kopf-Text',
  'defaultInvoiceFooterText': 'Standard Rechnung Fuß-Text',
  'defaultInvoiceHeaderText': 'Standard Rechnung Kopf-Text',
  'defaultModuleFee': 'Seminar-Modul Teilnehmergebühr',
  'defaultOfferFooterText': 'Standard Angebot Fuß-Text',
  'defaultOfferHeaderText': 'Standard Angebot Kopf-Text',
  'DefaultTextTemplates': 'Standard Text-Vorlagen',
  'Delete': 'Löschen',
  'Deleted': 'gelöscht',
  'deliveryAddress': 'Lieferadresse',
  'DeliveryAddress': 'Lieferadresse',
  'DeliveryAddresses': 'Lieferadressen',
  'description': 'Beschreibung',
  'Description': 'Beschreibung',
  'Detail': 'Detail',
  'Discount created': 'Rabatt erstellt',
  'Discount deleted': 'Rabatt gelöscht',
  'Discount updated': 'Rabatt aktualisiert',
  'discount': 'Rabatt',
  'Discount': 'Rabatt',
  'DiscountAmount': 'Höhe des Rabatts',
  'discountDescription': 'Grund für den Rabatt',
  'discountFixed': 'Betrag',
  'discountHint': 'in Prozent',
  'discountPercentage': 'Prozentual',
  'Discounts': 'Rabatte',
  'DiscountType': 'Rabatt-Art',
  'domain': 'Domain',
  'Done': 'Erledigt',
  'Download': 'Download',
  'DownloadAllParticipantConfirmationsPackage': 'Teilnahmebestätigungen als Paket herunterladen',
  'DownloadCsv': 'Csv herunterladen',
  'DownloadCustomerCsv': 'Kundendaten herunterladen',
  'DownloadInvoices': 'Paket herunterladen',
  'DownloadInvoicesExplanation': 'Rechnungen, Gutschriften und Storno-Rechnungen für den angegebenen Zeitraum.',
  'DownloadOfferConfirm': 'Auftragsbestätigung herunterladen',
  'DownloadParticipantConfirmation': 'Teilnahmebestätigung herunterladen',
  'DunningCreated': 'Mahnung erstellt',
  'dunningFee': 'Mahngebühr',
  'DunningFee': 'Mahngebühr',
  'lastSentAt': 'zuletzt versendet',
  'DunningLetters': 'Mahnungen',
  'DunningLetterSendText': 'Soll die Mahnung per Email verschickt werden?',
  'DunningLetterSent': 'Die Mahnung wurde erfolgreich per Email verschickt.',
  'dunningLevel': 'Mahnstufe',
  'DunningLevel': 'Mahnstufe',
  'dunningLevels': 'Mahnstufen',
  'Edit': 'Editieren',
  'EditAddress': 'Adresse bearbeiten',
  'EditContact': 'Kontakt bearbeiten',
  'editDoubleClick': '* Um diese Felder zu bearbeiten Doppelklicken.',
  'EditDunningLevel': 'Mahnstufe bearbeiten',
  'EditMenuEntry': 'Menu Eintrag bearbeiten',
  'EditPage': 'Seite bearbeiten',
  'EditPaymentMethod': 'Zahlungsart bearbeiten',
  'EditSeminar': 'Seminar editieren',
  'EditTextTemplate': 'Textvorlage editieren',
  'email': 'E-Mail',
  'EnableAutoInvoice': 'Rechnungsversand ab sofort aktivieren?',
  'enabled': 'Aktiviert',
  'end': 'Ende',
  'End': 'Ende',
  'entryOrder': 'Reihenfolge',
  'Expense': 'Ausgabe',
  'ExpenseAmount': 'Betrag',
  'ExpensePerParticipant': 'p.T.',
  'Expenses': 'Ausgaben',
  'ExpenseText': 'Beschreibung',
  'externalMeetingPassword': 'Passwort',
  'externalMeetingRoom': 'Externes Online Meeting',
  'externalMeetingTitle': 'Meeting-System (z.B. Web-Ex, MS-Teams, GoToMeeting)',
  'faoTraining': 'nach § 15 FAO',
  'faoTrainingEnabled': 'Seminare nach § 15 FAO',
  'fax_business': 'Fax (Geschäftlich)',
  'fax_private': 'Fax (Privat)',
  'fax': 'Fax ',
  'fee': 'Teilnehmergebühr',
  'Fee': 'Teilnehmergebühr',
  'Feedback': 'Feedback',
  'feeMinParticipants': 'min. Teilnehmerzahl',
  'feeMinParticipantsHelp': 'min. Teilnehmerzahl ab wann dieser Satz / Bonus pro weiteren Teilnehmer hinzugefügt wird',
  'feePerParticipant': 'nach Teilnehmerzahl',
  'FeeRate': 'Satz',
  'Fees': 'Teilnehmergebühren',
  'FeeTenant': 'Mandant',
  'FeeUnit': 'Einheit',
  'Field is required': 'Bitte Feld ausfüllen',
  'Field': 'Feld',
  'FileAdd': 'Datei hinzufügen',
  'FileAdded': 'Datei hinzugefügt',
  'Files': 'Dateien',
  'filesToDownload': 'Dateien / Downloads',
  'Filter': 'Filter',
  'Filters': 'Filter',
  'firstName': 'Vorname',
  'firstSeminarModuleStartDate': 'Seminar Start',
  'fixed': 'Betrag',
  'flatRate': 'Tagungspauschale p.P.',
  'footerText': 'Fuß-Text',
  'format': 'Format',
  'from': 'von',
  'frozen': 'Festgeschr.',
  'FurtherExpenses': 'Weitere Ausgaben',
  'General': 'General',
  'Generate & Download': 'Erstellen & herunterladen',
  'GenerateDunningLetter': 'Mahnung erstellen',
  'generateInvoiceForNumberOfParticipants': 'Rechnung an Kunden pro Teilnehmer erstellen',
  'GenerateParticipantEmailListCsv': 'Teilnehmer-E-Mail-Liste erstellen (CSV)',
  'GenerateParticipantList': 'Teilnehmerliste erstellen (PDF)',
  'GenerateParticipantListCsv': 'Teilnehmerliste erstellen (CSV)',
  'GenerateParticipantNameCardListCsv': 'Namenskarten erstellen (CSV)',
  'GenerateSignatureList': 'Anwesenheitsliste erstellen (PDF)',
  'GotoSeminar': 'Zum Seminar',
  'groups': 'Gruppen',
  'happens': 'Findet statt',
  'headerText': 'Kopf-Text',
  'High': 'Hoch',
  'Home': 'Start',
  'hour': 'Stunde',
  'iban': 'IBAN',
  'In progress': 'In Bearbeitung',
  'individuallyBookable': 'Buchbar pro Teilnehmer',
  'InProgress': 'In Bearbeitung',
  'institute': 'Kreditinstitut',
  'Invalid credentials.': 'Ungültige Zugangsdaten',
  'Invalid email address.': 'Keine gültige E-Mail-Adresse',
  'Invalid URL': 'Bitte vollständige URL angeben (z.B. https://www.erica-gilb.de)',
  'InviteAllParticipants': 'Alle einladen',
  'InviteAllParticipantsNow': '<strong>Jetzt alle Teilnehmer einladen?</strong><br/>Bereits eingeladene Teilnehmer werden nicht erneut benachrichtigt.',
  'InviteAllSpeakers': 'Referenten einladen',
  'InviteParticipant': 'Diesem Teilnehmer Einladungs-E-Mail zusenden?',
  'InviteParticipantAgain': 'Diesem Teilnehmer erneut die Einladungs-E-Mail zusenden?',
  'invoice': 'Rechnung',
  'invoiceAddress': 'Rechnungsadresse',
  'InvoiceAddress': 'Rechnungsadresse',
  'InvoiceAddresses': 'Rechnungsadressen',
  'InvoiceApproval': 'Rechnungsfreigabe',
  'InvoiceCancelled': 'Rechnung wurde storniert',
  'invoiceDate': 'Datum',
  'InvoiceFullyPaid': 'Die Rechnung ist bereits vollständig bezahlt.',
  'invoiceMailText': 'Rechnung Email Text',
  'invoiceNumber': 'Rechnung-Nr.',
  'invoiceNumberRange': 'Rechnungsnummer-Bereich',
  'invoices': 'Rechnungen',
  'Invoices': 'Rechnungen',
  'InvoiceSent': 'Rechnung wurde verschickt.',
  'InvoicesSentToParticipants': 'Rechnungen erstellt & an Teilnehmer versendet.',
  'invoiceTotal': 'Summe',
  'isCoSpeaker': 'Co Referent',
  'isDefault': 'Hauptbankverbindung',
  'isNotPresenceSeminar': 'Keine Präsenzseminare',
  'isPresenceSeminar': 'Präsenzseminare',
  'isSubscribed': 'Bestätigt',
  'IssueCreate': 'Aufgabe erstellen',
  'issues': 'Aufgaben',
  'Issues': 'Aufgaben',
  'issueUpdateSuccess': 'Aufgabe erfolgreich aktualisiert',
  'isUpdateSuccess': 'Erfolgreich aktualisiert',
  'Join as moderator': 'Als Moderator teilnehmen',
  'Join Url Moderator': 'Als Moderator teilnehmen',
  'JoinAsModerator': 'Als Moderator teilnehmen',
  'JoinModeratorOrAdd': 'Teilnehmen oder hinzufügen',
  'joinUrl': 'Link zum externen Meeting',
  'lastName': 'Nachname',
  'Latitude': 'Breitengrad',
  'letterPaperFirst': 'Briefpaper 1. Seite',
  'letterPaperFurther': 'Briefpaper weitere Seiten',
  'level': 'Stufe',
  'LinkWithOffer': 'Mit Angebot verknüpfen',
  'Loading...': 'Lade...',
  'Location': 'Ort',
  'locked': 'Gesperrt',
  'login': 'Login',
  'Login': 'Login',
  'LoginNow': 'Einloggen',
  'LoginRedirectInfo': 'Sie werden zum Login weitergeleitet',
  'logo': 'Logo',
  'Longitude': 'Längengrad',
  'Low': 'Niedrig',
  'mail_bcc': 'Blindkopie aller Emails',
  'mail_business': 'E-Mail (Geschäftlich)',
  'mail_invoice': 'Rechnungs E-Mail',
  'mail_module_booking': 'Empfänger für Seminar Buchungen',
  'mail_private': 'E-Mail (Privat)',
  'mail': 'E-Mail',
  'mailFromMail': 'Absender Email Adresse',
  'mailFromName': 'Email Absender Name',
  'Manage': 'Verwalten',
  'maxParticipants': 'max. Teilnehmer',
  'Medium': 'Mittel',
  'MeetingStarted': 'Seminar gestartet',
  'MeetingTypeChanged': 'Der Typ des Meetings hat sich geändert. Erneut die Einladungen verschicken?',
  'menuEntries': 'Menu Einträge',
  'menus': 'Menüs',
  'Menus': 'Menüs',
  'metaDescription': 'Meta Beschreibung',
  'metaTitle': 'Meta Titel',
  'MinChar6': 'Bitte mindestens 6 Zeichen angeben',
  'mobile_business': 'Mobil (Geschäftlich)',
  'mobile_private': 'Mobil (Privat)',
  'mobile': 'Mobil',
  'ModeratorEmail': 'E-Mail-Adresse',
  'ModeratorInvited': 'Moderator/in wurde eingeladen',
  'ModeratorName': 'Name (wird im Seminar-Raum angezeigt)',
  'Modules': 'Module',
  'month': 'Monat',
  'Month': 'Monat',
  'Monthly': 'Monatlich',
  'name': 'Name',
  'Name': 'Name',
  'Never': 'Niemals',
  'New Expense': 'Neue Ausgabe',
  'New Note': 'Neue Notiz',
  'New TimeSlot': 'Neuer Zeitraum',
  'New': 'Neu',
  'NewsletterSubscriptions': 'Newsletter Anmeldungen',
  'No Access': 'Kein Zugriff',
  'No results': 'Keine Ergebnisse',
  'No-Tenant': 'Keinen Mandanten auswählen',
  'no': 'nein',
  'No': 'Nein',
  'noCommentAvailable': 'Kein Kommentar verfügbar.',
  'noOnline': 'Kein Online-Meeting → Präsenz',
  'NoParticipantsYet': 'Noch keine Teilnehmer vorhanden',
  'noPostalScriptOrdered': 'Kein Script per Post bestellt.',
  'Not a valid url': 'keine gültige URL',
  'NotCompleted': 'Nicht Abgeschlossen',
  'NotConfirmed': 'Nicht Bestätigt',
  'Notes': 'Notizen',
  'NotesPrivate': 'Notizen (intern)',
  'number': 'Nummer',
  'Number': 'Nummer',
  'numberCycle': 'Nummern Wechsel',
  'Offer': 'Angebot',
  'offerable': 'Als Angebot erstellbar',
  'offerConfirmed': 'Bestätigt',
  'offerConfirmedFilterItem': 'Akzeptiert',
  'offerDate': 'Datum',
  'offerDeclinedFilterItem': 'Abgelehnt',
  'offerFilterConfirmed': 'Status',
  'offerGenerated': 'Erstellt',
  'offerNumber': 'Angebots-Nr.',
  'offerNumberRange': 'Angebotsnummer-Bereich',
  'offers': 'Angebote',
  'Offers': 'Angebote',
  'OK': 'Ok',
  'OnlineMeetingRoom': 'Online Seminar-Raum',
  'openInvoices': 'Offene Rechnungen',
  'openInvoicesDunningNotSendLong': 'Mit vorbereiteter, aber noch nicht gesendeter Mahnung',
  'optinExpiresAt': 'Anmeldung gültig bis',
  'orderConfirmationNumberRange': 'Auftragsbestätigungsnummer-Bereich',
  'ordinalNumeral': 'Pos.',
  'outpayment': 'Zahlungsausgang',
  'Overview': 'Übersicht',
  'Page': 'Seite',
  'pages': 'Seiten',
  'Pages': 'Seiten',
  'paid': 'Bezahlt',
  'Participant': 'Teilnehmer',
  'ParticipantAdded': 'Teilnehmer/in wurde hinzugefügt',
  'ParticipantConfirmationsSent': 'Teilnahmebestätigungen versendet',
  'ParticipantDelete': 'Teilnehmer/in löschen',
  'ParticipantDeleted': 'Teilnehmer/in wurde gelöscht',
  'ParticipantInformation': 'Teilnehmer-Infos',
  'ParticipantInvitationsSent': 'Einladungen wurden versendet.',
  'ParticipantInvite': 'Teilnehmer/in einladen',
  'ParticipantInvited': 'Teilnehmer/in wurde eingeladen',
  'ParticipantInvoiceSent': 'Teilnehmer Rechnung verschickt.',
  'ParticipantInvoicesSent': 'Teilnehmer Rechnungen verschickt.',
  'ParticipantName': 'Name des Teilnehmers',
  'ParticipantNotParticipatedSet': 'Teilnehmer als "hat nicht teilgenommen" setzten?',
  'ParticipantNotParticipatedToggle': 'Teilnehmer hat nicht teilgenommen',
  'ParticipantParticipatedSet': 'Teilnehmer als "hat teilgenommen" setzten?',
  'ParticipantParticipatedToggle': 'Teilnehmer hat teilgenommen',
  'Participants': 'Teilnehmer',
  'ParticipantUpdate': 'Teilnehmer/in  aktualisieren',
  'ParticipantUpdated': 'Teilnehmer/in wurde aktualisiert',
  'password': 'Passwort',
  'PaymentDelete': 'Zahlung löschen',
  'PaymentEdit': 'Zahlung bearbeiten',
  'PaymentMethod': 'Zahlungsart',
  'paymentMethods': 'Zahlungsarten',
  'Payments': 'Zahlungen',
  'percentage': 'Prozentual',
  'perParticipant': 'pro Teilnehmer',
  'Persist and lock?': 'Erstellen & sperren?',
  'phone_business': 'Telefon (Geschäftlich)',
  'phone_private': 'Telefon (Privat)',
  'phone': 'Telefon',
  'Please type something': 'Bitte Feld ausfüllen',
  'Please, insert a value bigger than zero!': 'Bitte eine größere Zahl als 0 angeben!',
  'Position': 'Position',
  'positionName': 'Pos. Name',
  'Positions': 'Positionen',
  'postalScript': 'Script per Post',
  'postalScriptShort': 'S.p.P',
  'PostingDate': 'Zahlungseingang',
  'presenceSeminar': 'Präsenzseminar',
  'presenceSeminarsEnabled': 'Präsenzseminare anlegbar',
  'PreviewParticipantConfirmation': 'Vorschau Teilnahmebestätigung',
  'priority': 'Priorität',
  'Priority': 'Priorität',
  'privateAddress': 'Privat',
  'PrivateAddress': 'Privatadresse',
  'PrivateAddresses': 'Privatadressen',
  'Profile': 'Profil',
  'profileImage': 'Profil-Bild',
  'profileImagePlaceholder': 'Profil-Bild auswählen',
  'profiles': 'Profil',
  'profileUpdateSuccess': 'Profil wurde erfolgreich gespeichert',
  'profitLoss': 'Gewinn / Verlust',
  'publicBookable': 'Öffentlich buchbar',
  'publicVisibility': 'Öffentlich sichtbar',
  'quantity': 'Anzahl',
  'quantityUnit.days': 'Tag(e)',
  'quantityUnit.piece': 'Stück',
  'quantityUnit': 'Einheit',
  'Records per page:': 'Einträge pro Seite:',
  'refreshData': 'Daten aktualisieren',
  'RefreshRoomStatus': 'Raum-Status aktualisieren',
  'registrationDate': 'Anmeldedatum',
  'Reply': 'Antworten',
  'ReSendInvoice': 'Rechnung erneut versenden',
  'Reset': 'Zurücksetzen',
  'roles': 'Rollen',
  'salutation': 'Anrede',
  'Save': 'Speichern',
  'saveCreditFirst': 'Um Positionen anzulegen bitte zuerst die Gutschrift speichern.',
  'saveInvoiceFirst': 'Um Positionen anzulegen bitte zuerst die Rechnung speichern.',
  'saveOfferFirst': 'Um Positionen anzulegen bitte zuerst das Angebot speichern.',
  'Seminar-Settings': 'Seminar',
  'Seminar': 'Seminar',
  'seminarContent': 'Seminar-Inhalt',
  'SeminarCreated': 'Seminar wurde erstellt',
  'Seminare': 'Seminare',
  'SeminarExpenses': 'Seminar-Ausgaben',
  'SeminarInfos': 'Informationen zum Seminar',
  'SeminarList': 'Übersicht',
  'SeminarModule': 'Seminar Modul',
  'SeminarModuleCancelled': 'Modul storniert.',
  'SeminarModules': 'Seminar Module',
  'seminarNumberRange': 'Seminar Nummernbereich',
  'seminars': 'Seminare',
  'Seminars': 'Seminare',
  'SeminarUpdated': 'Seminar wurde aktualisiert',
  'SendDunningLetter': 'Mahnung verschicken',
  'SendInvitationToAllParticipants': 'Alle Teilnehmer einladen',
  'SendInvoice': 'Rechnung senden',
  'SendInvoices': 'Rechnungen versenden',
  'SendModInvitationViaMailHint': 'Sendet den Link an die angegebene E-Mail-Adresse, anstatt direkt teilzunehmen.',
  'SendParticipantConfirmations': 'Teilnahmebestätigungen versenden',
  'SendSpeakerCertificates': 'Referentenbescheinigung versenden',
  'SetCancelled': 'Seminar stornieren',
  'SetCompleted': 'Seminar abschließen',
  'SetConfirmed': 'Seminar bestätigen',
  'SetDisable': 'Seminar deaktivieren',
  'SetEnable': 'Seminar aktivieren',
  'SetPaidMessage': 'Die Rechnung wirklich als bezahlt markieren? Dies kann nicht Rückgängig gemacht werden!',
  'Settings': 'Einstellungen',
  'showSeminarsAfter': 'Startdatum nach dem',
  'showVenueDetails': 'Details zum Veranstaltungsort',
  'slug': 'Slug',
  'Speaker': 'Referent',
  'SpeakerAccount': 'Referent Basis-Daten',
  'SpeakerCalendar': 'Kalender',
  'SpeakerCertificatesSent': 'Referenten Zertifikate versendet',
  'SpeakerFees': 'Honorare',
  'SpeakerFeeTitle': 'Honorar Referenten',
  'SpeakerList': 'Referenten Liste',
  'speakers': 'Referenten',
  'Speakers': 'Referenten',
  'SpeakerTenantUsers': 'Referent Mandanten-Zugriff',
  'speakerVita': 'Vita',
  'start': 'Start',
  'Start': 'Start',
  'StartMeeting': 'Raum starten',
  'startNumber': 'Start Nummer',
  'status': 'Status',
  'Status': 'Status',
  'streetNr': 'Straße & Hausnummer',
  'Submit': 'Absenden',
  'successfullyCreated': 'Erfolgreich angelegt',
  'successfullyDeleted': 'Erfolgreich gelöscht',
  'successfullyUpdated': 'Erfolgreich aktualisiert',
  'targetPage': 'Zielseite',
  'targetUrl': 'Ziel-URL',
  'teachingUnits': 'Unterrichtsstunden',
  'teaser': 'Teaser',
  'Tenant Create': 'Mandant erstellen',
  'tenant_groups': 'Mandanten Gruppen',
  'Tenant-Groups': 'Mandanten-Gruppen',
  'tenantAccountSettingCreateSuccess': 'Erfolgreich gespeichert',
  'TenantAccountSettings': 'Account-Settings',
  'tenantAccountSettingUpdateSuccess': 'Erfolgreich aktualisiert',
  'tenantCompanySettingCreateSuccess': 'Erfolgreich gespeichert',
  'tenantCompanySettingUpdateSuccess': 'Erfolgreich aktualisiert',
  'tenants': 'Mandanten',
  'Tenants': 'Mandanten',
  'tenantSeminarSettingCreateSuccess': 'Seminar-Einstellung erfolgreich gespeichert',
  'TenantSeminarSettings': 'Seminar Einstellungen',
  'tenantSeminarSettingUpdateSuccess': 'Seminar-Einstellung erfolgreich gespeichert',
  'TenantUserGroup': 'Benutzergruppe',
  'TenantUserName': 'Mandant',
  'TenantUsersAccess': 'Mandanten-Zugriff',
  'TenantUserTenant': 'Mandant',
  'text_templates': 'Text-Vorlagen',
  'text': 'Text',
  'textContent': 'Inhalt',
  'TextTemplate': 'Textvorlage',
  'textTemplates': 'Text-Vorlagen',
  'TextTemplates': 'Textvorlagen',
  'textTemplateUpdateSuccess': 'Erfolgreich aktualisiert',
  'timeHours': 'Zeitstunden',
  'timeRange': 'Zeitspanne',
  'timeRangeDays': 'Zeitspanne (in Tagen)',
  'TimeSlot': 'Zeitraum',
  'TimeSlots': 'Zeiträume',
  'title': 'Titel',
  'Today': 'Heute',
  'Topic': 'Thema',
  'TopicDeleteSuccess': 'Erfolgreich gelöscht',
  'topics': 'Themen',
  'Topics': 'Themen',
  'TopicUpdateSuccess': 'Erfolgreich aktualisiert',
  'type': 'Typ',
  'Type': 'Typ',
  'unitPrice': 'Einzelpreis',
  'Update': 'Aktualisieren',
  'Updated': 'aktualisiert',
  'Upload': 'Hochladen',
  'value': 'Wert',
  'Value': 'Wert',
  'Venue': 'Veranstaltungsort',
  'venueImagePlaceHolder': 'Bild vom Veranstaltungsort',
  'VenueInternalData': 'Interne Daten zum Veranstaltungsort',
  'venueRate': 'Gebühr / Miete des Veranstaltungsortes',
  'venues': 'Veranstaltungsorte',
  'Venues': 'Veranstaltungsorte',
  'web_business': 'Web (Geschäftlich)',
  'web_private': 'Web (Privat)',
  'web': 'Web',
  'website': 'Webseite',
  'Week': 'Woche',
  'Weekly': 'Wöchentlich',
  'year': 'Jahr',
  'Yes, delete now': 'Ja, jetzt löschen',
  'Yes, set paid now': 'Ja, jetzt als bezahlt markieren',
  'yes': 'ja',
  'Yes': 'Ja',
  'YesAndSendInvoices': 'Ja und Rechnungen versenden',
  'zipCode': 'PLZ',
};
