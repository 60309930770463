export default {
    path: '/menus',
    name: 'menus',
    component: () => import('../components/menu/Layout'),
    redirect: { name: 'MenuList' },
    children: [
      {
        name: 'MenuList',
        path: '',
        component: () => import('../views/menu/List'),
        meta: {
          listMenu: true
        }
      },
      {
        name: 'MenuCreate',
        path: 'new',
        component: () => import('../views/menu/Create'),
        meta: {
          createMenu: true
        }
      },
      {
        name: 'MenuUpdate',
        path: ':id/edit',
        component: () => import('../views/menu/Update'),
        meta: {
          updateMenu: true
        }
      },
    ],
    meta: {
      admin: true
    }
  };
  